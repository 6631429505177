#adtagheader, .mobile_adlabelleft {
	display:none !important;
}

.header-cme-rclist.bb {
    border-bottom: 4px solid #701104;
    padding-bottom: 4px;
}

.meta-info {
	display:none;
}
.posting-date {
	display:none;
}
	
.activityData {
	display:flex;
	justify-content:space-around;
	margin-top:15px;
}
.activity-data-divider {
    background-image: url('/pi/global/icons/icon-dotted-divider.png');
    background-size: 100%;
    width: 1px;
}

.abim-credits, .primary-credits, .length {
	display:inline-block;
	text-align:center;	
	font-family: 'Roboto Condensed', arial, sans-serif;
	font-size:13px;
	margin-left:0px;
}
.length {
	vertical-align:top;
	display:inline-block;
	text-align:center;	
	font-family: 'Roboto Condensed', arial, sans-serif;
	font-size:13px;
	margin-left:0px;
}
.credits-number {
	font-size:18px;
}
.saveState {
    min-width: 50px;
    text-align: center;
	text-transform: uppercase;
    color: #01808d;
    font-family: 'Roboto Condensed', arial, sans-serif;
    font-size: 14px;
}
.star-blue {
    display: inline-block;
    background-image: url(/pi/global/icons/star-blue-hollow.png);
    background-size: 18px;
    width: 18px;
    height: 18px;
    background-position: 0px 2px;
    background-repeat: no-repeat;
	transition: all 0.1s linear;
	margin-bottom:3px;
}
.saveState:hover .star-blue {
    display: inline-block;
    background-image: url(/pi/global/icons/star-blue-filled.png);
    background-size: 18px;
    width: 18px;
    height: 18px;
    background-position: 0px 2px;
}
.saved .star-blue {
    display: inline-block;
    background-image: url(/pi/global/icons/star-blue-filled.png);
    background-size: 18px;
    width: 18px;
    height: 18px;
    background-position: 0px 2px;
    background-repeat: no-repeat;
	/*margin-top:5px;*/
}
.saveState {
	display:inline-block;
	float:right;
	cursor:pointer;
	text-align: center;
	margin-right:3px;
}
.activityData .credits-number {
	display:block;
}

.view-more-saved {
	text-align: center;
    width: 100%;
    display: block;
    padding-top: 10px;
}





.hidden {
	display:none !important;
}

.displayed {
	display:block;
}


.hide-branding-bar #brandingBar {
	display:none !important;
}
.hide-branding-bar .page-header.nrc {
	border-bottom: 0px;
	padding-bottom: 0px;
}


#brandingBar {
	width: 100%;
	background-color: #701104;
	height: 40px;
	line-height:40px;
	position:relative;
	z-index: 2005;
    font-size: 1rem;
}
#brandingBarWrap {
	display: flex;
    justify-content: space-between;
	margin:auto;
	width:100%;
	max-width:1280px;
}
#brandingBar.active {
	position: fixed;
	top: 0;
}
#brandingBar.nonhp {
	height: 46px;
    line-height: 46px;
    opacity: 1;
	position:relative;
}
#brandingBar.nonhp.active {
	
}


.supports-sticky #brandingBar {
    position: -webkit-sticky;
    position: -moz-sticky;
    position: -o-sticky;
    position: -ms-sticky;
    position: sticky;
}
.supports-sticky #brandingBar.active {
    position: -webkit-sticky;
    position: -moz-sticky;
    position: -o-sticky;
    position: -ms-sticky;
    position: sticky;
}

#brandingBarPlaceholder.active {
	position:relative;
}
#brandingBarPlaceholder {
	height:40px;
	position: absolute;
}

#brandingBar #savedActivitiesBrandingBar {
    background-image: url(/pi/global/icons/saved-activity-branding-bar.svg);
    background-repeat: no-repeat;
	background-position: 12px 10px;
    min-width: 30px;
	cursor:pointer;
	transition: height 0.3s ease-in;
	height:0px;
}

#brandingBar.active #savedActivitiesBrandingBar {
	height:40px;
}

#brandingBar .center-branding-text {
	font-family: 'Roboto Condensed', arial, sans-serif;
	font-size: 16px;
	color: #fff;
	text-transform: uppercase;
	cursor:pointer;
	white-space: nowrap;
	transition: padding-left 0.3s ease-out;
}
#brandingBar.active .center-branding-text {
    background-image: url(/pi/global/icons/medscape-branding-bar.svg);
    background-size: 90px;
    background-repeat: no-repeat;
    background-position: 0px 10px;
    padding-left: 100px;
}

 #brandingBar #activityTrackerBrandingBar {
     background-image: url(/pi/global/icons/activity-tracker-branding-bar.svg);
    background-repeat: no-repeat;
	background-position: 0px 10px;
    min-width: 30px;
	cursor:pointer;
	transition: height 0.3s ease-in;
	height:0px;
 }
#brandingBar.active #activityTrackerBrandingBar {
	height:40px;
}

.tracker-modal {
	display:none;
}
.tracker-modal.active { 
	display:none;
}



.tracker-modal .credit-name {
	display:block;
}
.credits-by-type:nth-child(4) {
	display:none;
}
.tracker-modal .credits-amount {
	font-size:22px;
	line-height:1.05;
	color: #333132;
	font-stretch:condensed;
}
.tracker-modal .credit-name {
  font-size: 12px;
  font-stretch: condensed;
  line-height: 1.5;
  text-align: center;
  color: #707070;
}
.tracker-modal .credits-by-type {
    border-right: dotted 1px;
    flex-grow:1;
}
.tracker-modal .credits-by-type:nth-child(3) {
    border-right: 0px;
    padding-right: 0px;
}
.tracker-modal .credits-by-type:last-child {
    border-right: 0px;
    padding-right: 0px;
}

.tracker-modal .morelink {
	margin-top:28px;
	display:inline-block;
	width: 162px;
	height: 41px;
	border-radius: 3px;
	background-color: #007cb0;
	cursor:pointer;
	transition: background-color 0.1s ease-in;
}

.tracker-modal .morelink:hover {
	background-color:#005b81;
}

.tracker-modal .morelink a {
	line-height:41px;
	font-family: 'proxima_nova_rgregular';
	color: #fff;
	text-transform:none;
	text-decoration:none;
	font-size:16px;
}

#bp-one-modal-bg {
	text-align:center;
	display:block;
	width: 100%;
	height: 100%;
	z-index: 6000001;
	position: fixed;
	top: 0;
	left: 0;
	background-color: rgba(0, 0, 0, 0.5);
}

#bp-one-modal-box {
    width: 60%;
    background-color: white;
    padding: 10%;
    margin: 0% 10% 10% 10%;
    display: block;
	font-size: 18px;
}
#bp-one-modal-box a {
    color: #0c87a4;
}
.modal-top-layer {
	height: 44px;
    background-color: #2f2148;
    display: block;
    width: 80%;
    margin: 10% 10% 0% 10%;
	font-family: 'Roboto Condensed', arial, sans-serif;
    color: #fff;
    text-align: left;
    line-height: 44px;
    font-size: 16px;
    text-transform: uppercase;
}
.modal-top-layer .close-x {
	 float: right;
    cursor: pointer;
    background-image: url(/pi/global/icons/icon-filter-x.png);
    width: 25px;
    height: 25px;
    background-size: 25px;
    background-repeat: no-repeat;
    background-position: center;
    padding: 10px;
    padding-right: 25px;
}

.modal-top-layer .modal-spacer {
	width:10px;
	display:inline-block;
}
#bp-one-modal-box .modal-header {
    display: block;
    font-size: 34px;
    margin-bottom: 12px;
}
.saved-modal .modal-saved-text, .saved-modal .modal-header {
	display:none;
}

.saved-modal .byline {
    font-family: 'proxima_nova_rgregular';
    color: #999;
    font-size: 12px;
    margin-top: 0px;
}
.saved-modal .saved .star-blue {
    margin-bottom: 5px;
	margin-top:0px;
}
.saved-modal .activityTitle .title {
	color: #333132;
    line-height:1.13;
    font-size:16px;
    font-family: 'Roboto Condensed', arial, sans-serif;
}
.saved-modal .activityData {
    border-bottom: 1px solid #fff;
    padding-bottom: 18px;
	margin-top: 5px;
}
.saved-modal .activityTitle {
	padding-top:18px;
}
.saved-modal .activityTitle a {
	color:#333132;
}
.saved-modal .saveState {
    text-transform: uppercase;
    margin-top: 2px;
    color: #01808d;
    font-family: 'Roboto Condensed', arial, sans-serif;
	font-size:14px;
}
.saved-modal .saved-card-bb:first-child {
	margin-top:-30px;
}

.saved-modal.three-or-less .saved-card-bb:last-child .activityData {
	border:none;
}

.saved-modal .saved-card-bb.fade-away {
	opacity:0;
	transition: opacity 0.5s ease-out;
}

.saved-modal {
	display:none;
}

.activityData {
	margin-top:15px;
}

.saved-modal .abim-credits, .saved-modal .primary-credits, .saved-modal .length {
	display:inline-block;
	text-align:center;	
	font-family: 'Roboto Condensed', arial, sans-serif;
	font-size:13px;
	margin-left:0px;
}
.saved-modal .length {
	vertical-align:top;
	display:inline-block;
	text-align:center;	
	font-family: 'Roboto Condensed', arial, sans-serif;
	font-size:13px;
	margin-left:0px;
	/* border-right: solid 1px rgba(47, 32, 72, 0.33); */
}
.saved-modal .credits-number {
	font-size:18px;
}

.saved-modal .saveState {
    min-width: 50px;
    text-align: center;
	text-transform: uppercase;
    color: #01808d;
    font-family: 'Roboto Condensed', arial, sans-serif;
    font-size: 14px;
}

.saved-modal .star-blue {
    display: inline-block;
    background-image: url(/pi/global/icons/star-blue-hollow.png);
    background-size: 18px;
    width: 18px;
    height: 18px;
    background-position: 0px 2px;
    background-repeat: no-repeat;
	transition: all 0.1s linear;
	margin-bottom:3px;
}


.saved-modal .saved .star-blue {
    display: inline-block;
    background-image: url(/pi/global/icons/star-blue-filled-2.png);
    background-size: 18px;
    width: 18px;
    height: 18px;
    background-position: 0px 2px;
    background-repeat: no-repeat;
}
.saved-modal .saveState.saved:hover .star-blue {
    display: inline-block;
    background-image: url(/pi/global/icons/star-blue-hollow-2.png);
    background-size: 18px;
    width: 18px;
    height: 18px;
    background-position: 0px 2px;
}

.saved-modal .saveState {
	display:inline-block;
	float:right;
	cursor:pointer;
	text-align: center;
	margin-right:3px;
}


.totals-by-type {
	display:flex;
	justify-content: space-around;
	font-family: 'Roboto Condensed', arial, sans-serif;
}



@media (max-width:768px) {
	/*at the moment, fullscreen audio-slides have a paradoxical z-index setup... unclicked videos are 8000, fullscreen active video is 98 and the close button is 9500.  Placing the branding bar at 8001 and moving the fullscreen up to 8002 will allow for proper layering (above the initial video thumbnail, below the fullscreen & below the close button) -- EB 17.10.24*/
	.has-video.is-mobile #brandingBar {
		z-index:8001;
	}
	#edu_article_content .cme.is-mobile.is-fullscreen, .cme.is-mobile.is-fullscreen {
		z-index:8002
	}
}

	
@media (max-width: 1023px) {
	.tracker-modal:before {
		margin-left: -90px;
	}
}
	
@media (min-width:768px) {

	#bp-one-modal-bg {
		display:none;
	}
	.saved-modal {
		display:none;
	}

	.view-more-saved a {
		text-align: center;
		clear: both;
		-webkit-border-radius: 3px;
		-moz-border-radius: 3px;
		border-radius: 3px;
		font-family: 'proxima_nova_rgregular', arial, sans-serif;
		font-size: 14px;
		color: #fff;
		display: inline-block;
		background-color: #007cb0;
		cursor: pointer;
		transition: background-color 0.1s ease-in;
		background-repeat: no-repeat;
		background-position: right 14px center;
		background-size: 22px 19px;
		padding: 11px 16px;
		white-space: nowrap;
		text-decoration: none;
		margin-top: 8px;
	}
	.view-more-saved a:hover {
		-o-transition: 0.5s;
		-ms-transition: 0.5s;
		-moz-transition: 0.5s;
		-webkit-transition: 0.5s;
		transition: 0.5s;
		background-color:#005b81;
	}
	
	
	.saved-modal.active {
		right: inherit;
		display: block;
		margin: 46px 0px 0px 0px;
		width: 252px;
		padding: 38px 20px 25px 20px;
		background-color: #e2e0e0;
		box-shadow: 0 3px 2px 0 rgba(0, 0, 0, 0.33);
		cursor: default;
		line-height: 25px;
		position: fixed;
		top: 0;
		opacity: 1;
		text-align:left;
	}
	.saved-modal.active .modal-saved-text, .saved-modal.active .modal-header {
		display:block;
	}
	.saved-modal:before {
		margin-top: -43px;
		width: 0;
		height: 0;
		border-left: 8px solid transparent;
		border-right: 8px solid transparent;
		border-bottom: 5px solid #e2e0e0;
		content: ' ';
		position: absolute;
		left:49%;
	}
	.saved-modal .modal-header {
		color: #333132;
		font-size:34px;
		font-family: 'proxima_nova_ltlight';
		line-height: 1;
	}
	.saved-modal .modal-saved-text {
		margin-top:12px;
		font-size:18px;
	}

	.tracker-modal {
		display:none;
	}
	.tracker-modal a {
		color:#0c87a4;
	}
	.tracker-modal.active { 
		display:block;
		margin: 46px 0px 0px -115px;
		width: 230px;
		text-align: center;
		padding: 38px 30px 45px 30px;
		background-color: #e2e0e0;
		box-shadow: 0 3px 2px 0 rgba(0, 0, 0, 0.33);
		cursor:default;
		line-height: 25px;
		position: fixed;
		top: 0;
		right: 5px;
		opacity:1;
	}
	.tracker-modal:before {
		margin-top: -43px;
		width: 0;
		height: 0;
		border-left: 8px solid transparent;
		border-right: 8px solid transparent;
		border-bottom: 5px solid #e2e0e0;
		content: ' ';
		position: absolute;
		text-align: center;
		margin-left: 0px;
	}

	.page-header.nrc {
		border-bottom: 4px solid #701104;
		padding-bottom: 4px;
	}
	.center-branding-text {
		display:none;
	}
	#brandingBar {
		height:0px;
		transition: all 0.15s linear;
		position:absolute;
		margin-top:0px;
		opacity:0;
	}
	#brandingBar.active {
		height:46px;
		line-height:46px;
		opacity:1;
	}
	#brandingBar #activityTrackerBrandingBar, #brandingBar #savedActivitiesBrandingBar {
		transition: height 0s linear;
	}
	#brandingBar.active .center-branding-text {
		display:block;
		font-size:20px;
		background-size: 100px;
		background-position: 0px 12px;
		padding-left: 108px;
	}	
	#brandingBar #savedActivitiesBrandingBar {
		background-position: 15px center;
		background-size: 20px;
		min-width: 40px;
	}
	#brandingBar.active #savedActivitiesBrandingBar {
		height:46px;
	}		
	#brandingBar.active #savedActivitiesBrandingBar:after {
		content: "SAVED ACTIVITIES";
		color: #fff;
		margin-left: 40px;
		font-family: 'Roboto Condensed', arial, sans-serif;
	}
	#brandingBar #activityTrackerBrandingBar {
		background-position: 15px center;
		background-size: 18px;
		min-width: 40px;
	}
	#brandingBar.active #activityTrackerBrandingBar {
		height:46px;
		margin-right:20px;
	}			
	#brandingBar.active #activityTrackerBrandingBar:after {
		content: "ACTIVITY TRACKER";
		color: #fff;
		margin-left:40px;	
		font-family: 'Roboto Condensed', arial, sans-serif;
	}	
}

@media (min-width:1024px) {
	#brandingBar.active #activityTrackerBrandingBar:after {
		background-image: url(/pi/global/icons/arrow-down-branding-bar.svg);
		background-repeat: no-repeat;
		background-position: right;
		padding-right: 25px;
	}
	#brandingBar.active #savedActivitiesBrandingBar:after {
		background-image: url(/pi/global/icons/arrow-down-branding-bar.svg);
		background-repeat: no-repeat;
		background-position: right;
		padding-right: 25px;		
	}
}

@media (min-width: 1370px) {
	.tracker-modal.active {
		margin-left: -70px;
	}
	.tracker-modal.active {
		position: absolute;
		right: inherit;
	}
}

.no-bb #brandingBar {
	display:none !important;
}
.no-bb .page-header.nrc {
    border-bottom: initial;
    padding-bottom: initial;
}
.bb-ebac-credits {
	margin-top: 15px;
}


#brandingBarItem {
	font-family: proxima_nova_rgregular, arial, sans-serif;
	display: block;
	background-color: #701104;
	z-index: 2005;
	font-size: 16px;
	top: 0;
	height: 40px;
	line-height: 40px;
	color: white;
	text-align: center;
	margin-top: 1px;
	transition: transform 0s ease-in, opacity 0s linear;
      }
      #brandingBarItem.has-nrc {
	margin-top: -10px;
      }
      
      .bar-wrapper {
	display: flex;
	justify-content: center;
	align-items: center;
      }

#brandingBarItem.no-view {
	transform: translateY(-100%);
	opacity: 0;
}      
      #brandingBarItem a {
	color: inherit;
	text-decoration: underline;
      }
      
      #brandingBarItem span.icon-svg {
	display: inline-block;
	color: white;
	margin-left: 10px;
	cursor: pointer;
      }
      
      .tooltip-container {
	position: relative;
      }
      
      #tooltip {
	position: absolute;
	padding: 18px;
	color: black;
	border-radius: 4px;
	background: white;
	box-shadow: 1px 1px 10px 3px #00000047;
	margin-top: 5px;
	display: none;
	line-height: 1.5;
	text-align: left;
	width: 400px;
      }
      
      #tooltip .tooltip_arrow {
	position: absolute;
	transform: translateX(-50%) translateY(1px);
	border-width: 14px;
	border-style: solid;
	border-color: transparent transparent #fff transparent;
	bottom: 100%;
	right: 0;
      }
      
      @media only screen and (max-width: 1023px) {
	#brandingBarItem, #tooltip {
		font-size: 14px;
	}
      }

      @media only screen and (max-width: 767px) {
	#brandingBarItem {
	  height: auto;
	  line-height: 1.5;
	  z-index: 2100;
	  padding: 5px 14px;
	  text-align: left;
	}
      
	#tooltip {
	  left: 10px;
	  transform: translateX(0);
	  top: 43px;
	  width: calc(90% - 20px);
	}
	#tooltip .tooltip_arrow {
	  transform: translateX(0) scale(0.8) translateY(8px);
	}
      }
      

body:has( #congrats-wrapper) .user-popup {
  z-index: 5002000;
}